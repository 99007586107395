$(function(){

	$('.country-selector').each(function(){
		var selector = $(this),
			options = selector.find('.country-options'),
			option = options.find('.country'),
			selected = selector.find('.country-selected');

		selector.click(function(e){
			if (!$(".country-options")[0].contains(e.target)) {
				$(this).toggleClass('active');
				options.toggleClass("open");
			}
		});
		option.click(function(){
			var t = $(this).find('img').clone();
			selected.empty().append(t);
			selector.removeClass("active");
			options.removeClass("open");
		});
		
		//Close lang selector box if clicked outside
		$(document).on("click", function(e) {
			if ($(".country-selector").hasClass("active")) {					
				if (!$(".country-selector")[0].contains(e.target)) {
					selector.removeClass("active");
					options.removeClass("open");
				}			
			}
		});
	});

	$('.language-selector').each(function(){
		var selector = $(this),
			options = selector.find('.language-options'),
			option = options.find('.language'),
			selected = selector.find('.language-selected');

		selector.click(function(e){
			if (!$(".language-options")[0].contains(e.target)) {
				$(this).toggleClass('active');
				options.toggleClass("open");
			}
		});
		option.click(function(){
			var t = $(this).text().trim();
			selected.text(t);
			selector.removeClass("active");
			options.removeClass("open");
		});
		
		//Close lang selector box if clicked outside
		$(document).on("click", function(e) {
			if ($(".language-selector").hasClass("active")) {					
				if (!$(".language-selector")[0].contains(e.target)) {
					selector.removeClass("active");
					options.removeClass("open");
				}			
			}
		});
	});

});
// ELEMS
var body = document.getElementsByTagName("body")[0];
// UNITS
var winH,
	winW,
	docH,
	scrolld,
	cursorX,
	cursorY;
// CONST
var dur = 300;
// MARKERS
var disableScroll = false;
$(function(){
	body = $('body');
    $('html,body').scroll(function(){
		scrolld = $(window).scrollTop();
		body.trigger('scrolld');
    }).scroll();
    $(window).scroll(function(){
		scrolld = $(window).scrollTop();
		body.trigger('scrolld');
    }).scroll();
    var resizeTimer;
    $(window).resize(function(){
		winH = $(window).height();
		winW = $(window).width();
		docH = $('#window').height();
		// HOOK THIS EVENT ELSEWHERE
		body.trigger('resizd');
    }).resize()
    // CHECK USER AGENT
    if(checkUserAgent() === 1){
    	body.addClass('ms-browser');
    }
});

function loadImg(src,remove){
	var img = $('<img>');
	img.hide();
	img.attr('src',src);
	body.append(img);
	if(remove){
		img.bind('load',function(){
			img.remove()
		})
	}
}

function checkUserAgent(){
	var rv = 0;
	if (/MSIE 10/i.test(navigator.userAgent)) {
	   // This is internet explorer 10
	   rv = 1;
	}

	if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
	    // This is internet explorer 9 or 11
	    rv = 1;
	}

	if (/Edge\/\d./i.test(navigator.userAgent)){
	   // This is Microsoft Edge
	   rv = 1;
	}
	// IS SAFARI?
	var ua = navigator.userAgent.toLowerCase(); 
	if (ua.indexOf('safari') != -1 &&  ua.indexOf('chrome') <= -1) {
	    rv = 1
	}
	return rv;
}
var initMap = function(){
	$('.map-office').each(function(i){
		var scope = $(this),
			$map = scope.attr('id') || 'map-office-'+i,
			lat = scope.attr('data-lat-lng').split(',')[0],
			lng = scope.attr('data-lat-lng').split(',')[1],
			zoom = scope.attr('data-zoom');

		scope.attr('id',$map);
		officeMap[i] = render_map_office(document.getElementById($map), i, lat, lng, zoom);

		drawCustomZoom(officeMap[i],scope);
	});
	$('.map-project').each(function(i){
		var scope = $(this),
			$map = scope.attr('id') || 'map-project-'+i,
			lat = scope.attr('data-lat-lng').split(',')[0],
			lng = scope.attr('data-lat-lng').split(',')[1],
			zoom = scope.attr('data-zoom'),
			json = scope.attr('data-json');

		scope.attr('id',$map);
		$.getJSON( json, function( data ) {
			projectMap[i] = render_map_project(document.getElementById($map), i, lat, lng, zoom, data);
			
			// Pan to avoid info panel
			var mapW = scope.width(),
				panelW = scope.parent().find('.project-map-info').outerWidth(),
				panOffset = (panelW)*-0.5,
				$mapCenter = new google.maps.LatLng(lat,lng);
			
			if(panelW < mapW){
				panMapWithOffset(projectMap[i], panOffset, 0);
			}

			drawCustomZoom(projectMap[i],scope);

		});
	});
	$('.map-location').each(function(i){
		var scope = $(this),
			$map = scope.attr('id') || 'map-location-'+i,
			lat = scope.attr('data-lat-lng').split(',')[0],
			lng = scope.attr('data-lat-lng').split(',')[1],
			zoom = scope.attr('data-zoom'),
			json = scope.attr('data-json');

		scope.attr('id',$map);
		$.getJSON( json, function( data ) {
			projectMap[i] = render_map_location(document.getElementById($map), i, lat, lng, zoom, data);
			
			// Pan to avoid info panel
			var mapW = scope.width(),
				panelW = scope.parent().find('.location-map-info').outerWidth(),
				panOffset = (panelW)*-0.5,
				$mapCenter = new google.maps.LatLng(lat,lng);
			
			if(panelW < mapW){
				panMapWithOffset(locationMap[i], panOffset, 0);
			}

			drawCustomZoom(locationMap[i],scope);

		});
	});
}

function drawCustomZoom(map,container){
	var cont = $('<div>',{class:'map-zoom-control'}),
		zIn = $('<button>',{class:'map-zoom-control-in'}),
		zOut = $('<button>',{class:'map-zoom-control-out'});
	zIn.click(function(){
		map.setZoom(map.getZoom()+1);
	}).appendTo(cont);
	zOut.click(function(){
		map.setZoom(map.getZoom()-1);
	}).appendTo(cont);
	container.append(cont);
}

function panMapWithOffset(map, x, y){
	google.maps.Map.prototype.panToWithOffset = function(latlng, offsetX, offsetY) {
	    var map = this;
	    var ov = new google.maps.OverlayView();
	    ov.onAdd = function() {
	        var proj = this.getProjection();
	        var aPoint = proj.fromLatLngToContainerPixel(latlng);
	        aPoint.x = aPoint.x+offsetX;
	        aPoint.y = aPoint.y+offsetY;
	        map.panTo(proj.fromContainerPixelToLatLng(aPoint));
	    }; 
	    ov.draw = function() {}; 
	    ov.setMap(this); 
	};
	map.panToWithOffset(map.getCenter(), x, y);
}

var addMapStyle = [
	    {
	        "featureType": "all",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "color": "#202c3e"
	            }
	        ]
	    },
	    {
	        "featureType": "all",
	        "elementType": "labels.text.fill",
	        "stylers": [
	            {
	                "gamma": 0.01
	            },
	            {
	                "lightness": 20
	            },
	            {
	                "weight": "1.39"
	            },
	            {
	                "color": "#58aad2"
	            }
	        ]
	    },
	    {
	        "featureType": "all",
	        "elementType": "labels.text.stroke",
	        "stylers": [
	            {
	                "weight": "0.96"
	            },
	            {
	                "saturation": "9"
	            },
	            {
	                "visibility": "on"
	            },
	            {
	                "color": "#000000"
	            }
	        ]
	    },
	    {
	        "featureType": "all",
	        "elementType": "labels.icon",
	        "stylers": [
	            {
	                "visibility": "off"
	            }
	        ]
	    },
	    {
	        "featureType": "landscape",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "lightness": 30
	            },
	            {
	                "saturation": "9"
	            },
	            {
	                "color": "#29446b"
	            }
	        ]
	    },
	    {
	        "featureType": "poi",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "saturation": 20
	            }
	        ]
	    },
	    {
	        "featureType": "poi.park",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "lightness": 20
	            },
	            {
	                "saturation": -20
	            }
	        ]
	    },
	    {
	        "featureType": "road",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "lightness": 10
	            },
	            {
	                "saturation": -30
	            }
	        ]
	    },
	    {
	        "featureType": "road",
	        "elementType": "geometry.fill",
	        "stylers": [
	            {
	                "color": "#193a55"
	            }
	        ]
	    },
	    {
	        "featureType": "road",
	        "elementType": "geometry.stroke",
	        "stylers": [
	            {
	                "saturation": 25
	            },
	            {
	                "lightness": 25
	            },
	            {
	                "weight": "0.01"
	            }
	        ]
	    },
	    {
	        "featureType": "water",
	        "elementType": "all",
	        "stylers": [
	            {
	                "lightness": -20
	            }
	        ]
	    }
	];
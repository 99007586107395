$(function(){
	var anchors = $('a').filter(function(){
		var url = String($(this).attr('href'));
		if(!url.startsWith('#')){
			return $(this)
		}
	}).filter(function(){
		return $(this).closest('#navigation-mobile').length < 1
	});
	anchors.click(function(event){
		event.preventDefault();
		var dest = $(this).attr('href');
		$('body').animate({opacity:0},animOffset);
		setTimeout(function(){
			window.location = dest;
		},animOffset+50)
	});
});
$(function(){
	var vidInline = $('.video-inline');
	vidInline.each(function(){
		var $this = $(this),
			video = $this.find('video'),
			button = $('<button>',{'class':'video-play'});
		$(this).append(button);
		button.click(function(){
			video.attr('controls','true');
			video.get(0).play();
			button.hide();
		});
	});
});
$(function(){
    $('nav#navigation-mobile').mmenu({
	    "extensions": [
              "position-right",
              "theme-dark"
           ]
    });
    var $menu = $('nav#navigation-mobile').data("mmenu");

    $('.button-menu').click(function(){
       $menu.open();
    });
});
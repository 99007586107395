$(window).bind("load",function(){
	var flicker = $('.flicker-keyword');
	
	setTimeout(function(){
		flicker.each(function(){
			var items = $(this).find('.keyword');
			var active = items.first();
			var t = 150;
			var action = setInterval(function(){
				active.animate({top:'10px',opacity:0},150).hide(0, function() {
					if(active.index() == items.length - 1){
						active = items.first()
					} else{
						active = active.next();
					}
					active.css({
						top:'-10px',
						opacity:0
					});
					active.show();
					active.animate({top:0,opacity:1},t);
				});
				
			},1200);
			$(this).addClass('active');
		});
	},1000);
});

$(function(){
	var catchment = $('.banner-catchment');
	var t = 2000;
	catchment.each(function(){
		var banner = $(this),
			items = banner.find('.add-flicker-keyword');
		items.each(function(){
			var figure = $('<figure>',{class:'img-bg img-flicked'});
			figure.css({
				'background-image':'url('+$(this).attr('data-img')+')',
				'display':'none'
			});
			banner.append(figure);
		});
		var figures = banner.find('.img-flicked');
		if(items.length > 1){
			var active = items.first();
			var action = setInterval(function(){
				active.hide();
				figures.eq(active.index()).fadeOut(t/2);
				if(active.index() == items.length - 1){
					active = items.first()
				} else{
					active = active.next();
				}
				active.show();
				figures.eq(active.index()).fadeIn(t/2);
			},t);
			banner.addClass('animate');
		}
	});
});
$(function(){
	var portfolio = $('.portfolio-scope'),
		filters = $('.portfolio-nav .tab');
	// LAYOUT
	portfolio.imagesLoaded(function(){
		portfolio.isotope({
			itemSelector: '.portfolio-item',
			layoutMode: 'fitRows'
		});
	});
	// FILTERING
	filters.click(function(){
		var cat = $(this).attr('data-filter');
		if(cat == 'cat-all'){
			cat = '*'
		} else{
			cat = '.' + cat;
		}
		portfolio.isotope({filter:cat});
	});
})
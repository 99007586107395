$(function(){
	var teamGrid = $('.grid-team'),
		filters = $('.grid-team-control .tab');
	
	var originLeft = true;

	//KSA check
	if ($("body").hasClass("lang-ksa")) {
		originLeft = false;
	}

	// LAYOUT
	teamGrid.imagesLoaded(function(){
		teamGrid.isotope({
			itemSelector: '.grid-team-member',
			layoutMode: 'fitRows',
			isOriginLeft: originLeft
		});
	});
	// FILTERING
	filters.click(function(){
		var cat = $(this).attr('data-filter');
		if(cat == 'cat-all'){
			cat = '*'
		} else{
			cat = '.' + cat;
		}
		teamGrid.isotope({filter:cat});
	});
	// ACTIVATION
	var members = teamGrid.find('.grid-team-member');
	members.click(function(){
		var html = $(this).clone().attr('style','');
		lightbox(html);
	});
})
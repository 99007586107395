var lightboxCont,
	lightboxMain,
	lightboxCloseBtn;
function lightbox(html){
	lightboxCont = $('<section>',{class:'lightbox-container'}),
	lightboxMain = $('<main>',{class:'lightbox-main'}),
	lightboxCloseBtn = $('<div>',{class:'lightbox-close'});
	lightboxMain.append(html);
	lightboxMain.hide().append(lightboxCloseBtn);
	lightboxCont.hide().append(lightboxMain);
	body.append(lightboxCont);
	lightboxCloseBtn.click(function(){
		lightboxClose()
	});
	lightboxOpen();
}
function lightboxOpen(){
	lightboxCont.fadeIn(dur);
	setTimeout(function(){
		lightboxMain.fadeIn(dur);
	},dur);
}
function lightboxClose(){
	lightboxMain.fadeOut(dur);
	setTimeout(function(){
		lightboxCont.fadeOut(dur);
	},dur)
	setTimeout(function(){
		lightboxCont.remove();
	},dur*2)
}
var officeMap = [];


function render_map_office($map, i, lat, lng, z) {

	// Global i
	var x = i;

	// This is the minimum zoom level that we'll allow
	var minZoomLevel = 15,
		maxZoomLevel = 18,
		z = parseInt(z);

	var $mapCenter = new google.maps.LatLng(lat,lng);

	var args = {
		center: $mapCenter,
		zoom: z,
		scrollwheel: false,
		mapTypeControl: false,
		draggable: true,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles : addMapStyle,
		disableDefaultUI: false,
		zoomControl: false,
		zoomControlOptions: {
		  position: google.maps.ControlPosition.RIGHT_BOTTOM
		},
		streetViewControl: false,
		streetViewControlOptions: {
		  position: google.maps.ControlPosition.LEFT_TOP
		},
		fullscreenControl: false
	};


	// Create map 

	officeMap[x] = new google.maps.Map($map, args);

	// // Add a markers reference
	officeMap[x].markers = [];

	// Add marker
	var $type = location.type,
		addMarker = 'images/add-map-icon.png';

	var $addMarker = {
			url:addMarker,
			size: new google.maps.Size(60,74),
		    origin: new google.maps.Point(0,0),
		    anchor: new google.maps.Point(30,74)
		};

	
	var marker = new google.maps.Marker({
		icon: $addMarker,
		position: $mapCenter,
		map: officeMap[x]
	});

	// Add to array
	officeMap[x].markers.push(marker);

	return officeMap[x];	
}
$(function(){
	$('.booking-nav-progress li').click(function(){
		$(this).addClass('active').siblings().removeClass('active');
	});
	// PROMO CODE
	$('#txtPromoCode').each(function(){
		var input = $(this),
			btn = input.next('#btnPromoApply');
		input.attr('autocomplete','off').keyup(function(){
			if(input.val().length > 1){
				btn.attr('disabled',false);		
			} else{
				btn.attr('disabled',true);
			}
		}).keyup();
	});
});
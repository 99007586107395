var $tooltip;
// UPDATE ON MOUSEMOVE
$(document).on('mousemove',function(){
	if($tooltip){
		updateCursor();
	}
});
function generateTooltip(content,isFeat){
	$tooltip = $('<div>',{id:"add-moving-tooltip"});
	$tooltip.append(content).appendTo('body');
}
function destroyTooltip(){
	$tooltip.remove();
}
function updateCursor(event) {
    var eventDoc, doc, body;
    event = event || window.event; // IE-ism
    // If pageX/Y aren't available and clientX/Y are,
    // calculate pageX/Y - logic taken from jQuery.
    // (This is to support old IE)
    if (event.pageX == null && event.clientX != null) {
        eventDoc = (event.target && event.target.ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        event.pageX = event.clientX +
          (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
          (doc && doc.clientLeft || body && body.clientLeft || 0);
        event.pageY = event.clientY +
          (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
          (doc && doc.clientTop  || body && body.clientTop  || 0 );
    }
    cursorY = event.clientY;
    cursorX = event.pageX;
   if($tooltip){
   	$tooltip.css({
   		'top':cursorY,
   		'left':cursorX
   	});
   }
}
var galleryHero = [],
	galleryReel = [];
$(function(){
	$('.gallery-hero').each(function(){
		var scope = $(this),
			single = scope.find('.gallery-hero-stage-single'),
			tile = scope.find('.gallery-hero-stage-tile'),
			control = scope.find('.gallery-hero-nav button'),
			items = scope.find('.gallery-hero-item');
		// DRAW HERO
		var hero = $('<main>',{class:'gallery-hero-hero'}),
			thumbnails = $('<footer>',{class:'gallery-hero-thumbs'}),
			thumbs = [];
		hero.append(items.clone());
		var heroItems = hero.children('li');
		items.each(function(i){
			thumbs[i] = $('<div>',{class:'gallery-hero-thumb'});
			thumbs[i].append($(this).clone().contents());
			thumbnails.append(thumbs[i]);
			thumbs[i].click(function(){
				hero.children('li').hide().eq(i).fadeIn();
			});
		});
		// PREV/NEXT
		var	hPrev = $('<button>',{class:'gallery-hero-nav-prev'}),
			hNext = $('<button>',{class:'gallery-hero-nav-next'});
		hero.append(hPrev,hNext);
		hPrev.add(hNext).click(function(){
			var target,
				current = heroItems.filter(':visible');
			if($(this).is('.gallery-hero-nav-prev')){
				target = current.prev('li');
				if(target.length < 1){
					target = heroItems.last();
				}
			} else{
				target = current.next('li')
				if(target.length < 1){
					target = heroItems.first();
				}
			}
			current.hide();
			target.fadeIn();
		})
		// THUMBS
		hero.add(thumbnails).appendTo(single);
		var $thumbSlider = thumbnails.flickity({
			wrapAround: true,
			autoPlay: false,
			adaptiveHeight: true,
			imagesLoaded: true,
			contain: true,
			freeScroll: true,
			responsive: true,
			pageDots:false,
			cellAlign: ksa ? "right" : "left",
			prevNextButtons: false
		});
		// CONTROL
		control.click(function(){
			var target = single;
			if($(this).hasClass('gallery-hero-view-tile')){
				target = tile;
			}
			$(this).addClass('active').siblings().removeClass('active');
			single.hide();
			tile.hide();
			target.fadeIn(300);
		});
		// ADD ACTIVE CLASSES 
		scope.addClass('active');
		scope.find('.gallery-hero-view-single').addClass('active');
	});
	$('.gallery-showreel').each(function(i){
		var scope = $(this),
			items = scope.find('.item');
		galleryReel[i] = scope.flickity({
			wrapAround: true,
			autoPlay: false,
			adaptiveHeight: true,
			imagesLoaded: true,
			contain: true,
			freeScroll: true,
			responsive: true,
			pageDots:false,
			cellAlign: 'left',
			prevNextButtons: false
		});
	});
});
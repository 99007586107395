var projectMap = [],
	mapCategories = [],
	projectMarkers = [];


function render_map_project($map, i, lat, lng, z, content) {

	// Global i
	var x = i;

	// This is the minimum zoom level that we'll allow
	var minZoomLevel = 15,
		maxZoomLevel = 18,
		z = parseInt(z);

	var $mapCenter = new google.maps.LatLng(lat,lng);

	var args = {
		center: $mapCenter,
		zoom: z,
		scrollwheel: false,
		mapTypeControl: false,
		draggable: true,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles : addMapStyle,
		disableDefaultUI: false,
		zoomControl: false,
		zoomControlOptions: {
		  position: google.maps.ControlPosition.RIGHT_BOTTOM
		},
		streetViewControl: false,
		streetViewControlOptions: {
		  position: google.maps.ControlPosition.LEFT_TOP
		},
		fullscreenControl: false
	};

	// Create map 
	projectMap[x] = new google.maps.Map($map, args);

	// Establish Categories
	mapCategories = content.categories;
	var catContainer = $('.project-map-key');
	for(var i in mapCategories) {
		add_category(mapCategories[i],catContainer)
	}

	// Add a markers reference
	projectMap[x].markers = [];

	var $markers = content.items;

	// Add markers
	for(var i in $markers) {
		add_marker($markers[i], projectMap[x], i);
	}

	return projectMap[x];	
}


function add_category($cat,container){
	var $cont = $('<div>',{class:'project-map-key-item'}),
		$icon = $('<i>'),
		$label = $('<span>');
	if($cat.isFeatured){
		$icon.addClass('featured');
	}
	$icon.css('background',$cat.color);
	$label.text($cat.label);
	$icon.add($label).appendTo($cont);
	container.append($cont);
}

function add_marker($marker, map, i, isDebug) {
	// Var
	var lat = $marker.loc.split(',')[0],
		lng = $marker.loc.split(',')[1],
		plotStatus = $marker.status,
		plotIcon = 'images/plot-icons/plot-'+plotStatus+'.png',
		cat = $marker.cat,
		color = mapCategories[cat].color,
		isFeat = mapCategories[cat].isFeatured,
		label = $marker.label,
		img = $marker.img,
		rad = isFeat ? 9 : 6;

	var cont = label;
	if(img != undefined && img.length > 0){
		cont = '<img src="'+img+'"/><span>' + label +'</span>';
	}

	var marker = new google.maps.Marker({
			position: new google.maps.LatLng(lat,lng),
			map: map,
			icon: {
				path: google.maps.SymbolPath.CIRCLE,
				fillOpacity: 1,
				fillColor: color,
				strokeOpacity: 0,
				strokeColor: '#fff000',
				strokeWeight: 0, 
			    scale: rad
			}
		});

	// Add to array
	map.markers.push(marker);

	// Get Z Index
	marker.set("myDepth", i);

	projectMarkers[i] = marker;

	google.maps.event.addListener(marker, 'mouseover', function() {
		marker.setOptions({zIndex:google.maps.Marker.MAX_ZINDEX + 1});
		generateTooltip(cont, false);
	});
	google.maps.event.addListener(marker, 'mouseout', function() {
		marker.setOptions({zIndex:marker.get('myDepth')});
		destroyTooltip();
	});

}
$(function(){
	var tabs = $('ul.tabs'),
		tab = $('ul.tabs .tab');
	// Collapsing when overflowing
	function condenseTabs(){
		var $this = $(this),
			tab = $(this).find('.tab'),
			w = 0;
		$this.removeClass('condensed').attr('style','');
		tab.attr('style','');
		if(tab.first().offset().top != tab.last().offset().top){
			$this.addClass('condensed');
			tab.show().each(function(){
				w = Math.max($(this).outerWidth(),w);
			}).attr('style','');
			$this.width(w);
		}
	}
	$(window).on('resize',function(){
		tabs.each(condenseTabs);
	}).resize();
	$('ul.tabs.condensed .tab').click(function(){
		if(!$(this).parent().hasClass('active') && $(this).hasClass('active')){
			$(this).siblings().slideDown(300);
			$(this).parent().addClass('active');
		} else{
			$(this).siblings().slideUp(300);
			$(this).parent().removeClass('active');
		}
	});
	// Basic Activation Toggling
	tab.click(function(){
		if(!$(this).hasClass('active')){
			$(this).addClass('active').siblings().removeClass('active');
		}
	});
});
var locationMap = [],
	locationMarkers = [];


function render_map_location($map, i, lat, lng, z, content) {

	// Global i
	var x = i;

	// This is the minimum zoom level that we'll allow
	var minZoomLevel = 15,
		maxZoomLevel = 18,
		z = parseInt(z);

	var $mapCenter = new google.maps.LatLng(lat,lng);

	var args = {
		center: $mapCenter,
		zoom: z,
		scrollwheel: false,
		mapTypeControl: false,
		draggable: true,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles : addMapStyle,
		disableDefaultUI: false,
		zoomControl: false,
		zoomControlOptions: {
		  position: google.maps.ControlPosition.RIGHT_BOTTOM
		},
		streetViewControl: false,
		streetViewControlOptions: {
		  position: google.maps.ControlPosition.LEFT_TOP
		},
		fullscreenControl: false
	};

	// Create map 
	locationMap[x] = new google.maps.Map($map, args);

	// Establish Categories
	mapCategories = content.categories;
	var catContainer = $('.location-map-key');
	for(var i in mapCategories) {
		add_category(mapCategories[i],catContainer)
	}

	// Add a markers reference
	locationMap[x].markers = [];

	var $markers = content.items;

	// Add markers
	for(var i in $markers) {
		add_marker($markers[i], locationMap[x], i);
	}

	return locationMap[x];	
}

$(function(){
	$('ul.slider-testimonials').each(function(){
		if($(this).children().length > 1){
			$(this).flickity({
				wrapAround: true,
				autoPlay: false,
				adaptiveHeight: true,
				imagesLoaded: true,
				contain: true,
				responsive: true,
				cellAlign: 'left',
				prevNextButtons: false
			});
		}
	});
	$('ul.slider-video-testimonials').each(function(){
		if($(this).children().length > 1){
			$(this).flickity({
				wrapAround: true,
				autoPlay: false,
				adaptiveHeight: true,
				imagesLoaded: true,
				contain: true,
				responsive: true,
				cellAlign: 'left',
				prevNextButtons: false,
				groupCells:true
			});
		}
		$(this).find('.video').click(function(){
			var video = $('<video>',{autoplay:true}),
				vidSrc = $(this).attr('data-video'),
				source = $('<source>',{
					type:'video/mp4'
				});
			source.attr('src',vidSrc).appendTo(video);
			lightbox(video);
		});
	});
});
var animOffset = 600,
	animQ,
	scrollThreshold = 0.95;
$(function(){
	var animItems = $('section.gbl,figure.video-inline, article');
	animItems.each(function(){
		if(!$(this).attr('anim-fx')){
			$(this).attr('anim-fx','fade');
		}
	});
	animItems = $('[anim-fx]');
	animItems.addClass('queued');
	var startItems = animItems.filter(function(){
		return $(this).offset().top < scrolld + scrollThreshold * winH
	});
	function revealItem(item){
		if(item.attr('anim-fx') == 'swell'){
			item.parent().height(item.height());
			item
				.hide()
				.removeClass('queued')
				.css({opacity:1})
				.show(animOffset);
		} else{
			item.removeClass('queued');
		}
	}
	animQ = setInterval(function(){
		var item = startItems.filter('.queued').first();
		if(item.length == 0){
			clearInterval(animQ)
		}
		revealItem(item);
	},animOffset*0.5);
	$('body').addClass('animated');
	body.on('scrolld',function(){
		console.log(scrolld)
		animItems.filter('.queued').each(function(){
			if($(this).offset().top < scrolld + scrollThreshold * winH){
				revealItem($(this))
			}
		});
	});
});
